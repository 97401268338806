<template>
  <section class="section">
    <!-- <h2 class="title">
      {{ $t('route.alerts-all') }}
    </h2> -->

    <box>
      <data-table
        ref="table"
        :items="itemsDebounced"
        :theme="$_ui_theme_tables"
        :per-page="20"
        sort-desc
        sort-by="created_at"
      >
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          field="notifier_type"
          :label="$t('type')"
          width="200px"
        >
          <template slot-scope="{ value }">
            <span class="tag is-info is-light">{{ value }}</span>
          </template>
        </data-column>
        <data-column
          field="notifier_name"
          :label="$t('alerts.notifier')"
        />
        <data-column
          field="details.events"
          :label="$t('status')"
          width="40%"
        >
          <template slot-scope="prop">
            <div v-if="prop.item.notifier_type === NotifierThreatFrequency">
              <div class="field is-grouped is-grouped-multiline">
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      {{ $t('client') }}
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.object.src_host }}
                    </div>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      {{ $t('threats.resource') }}
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.object.resource }}
                    </div>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      {{ $t('events') }}
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.events }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-else-if="prop.item.notifier_type === NotifierSensorMonitor">
              <div class="field is-grouped is-grouped-multiline">
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      CPU
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.events.cpu | fixed }}%
                    </div>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      RAM
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.events.ram | fixed }}%
                    </div>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <div class="tag is-primary is-uppercase">
                      DISK
                    </div>
                    <div class="tag is-info is-light">
                      {{ prop.item.details.events.disk | fixed }}%
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          :sortable="false"
        >
          <template slot-scope="props">
            <button
              class="button is-small is-primary is-light mr-2"
              @click.prevent="details(props.item)"
            >
              <octicon :icon="repo" /> <span>{{ $t('details') }}</span>
            </button>
            <button
              class="button is-small is-primary is-light mr-2"
              @click.prevent="resolve(props.item)"
            >
              <template v-if="isResolved(props.item)">
                <octicon :icon="reopened" /> <span>{{ $t('re-open') }}</span>
              </template>
              <template v-else>
                <octicon :icon="check" /> <span>{{ $t('resolved') }}</span>
              </template>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <div class="boxy">
        <h4 class="is-size-5 mb-2">
          {{ $t('information') }}
        </h4>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">{{ $t('time') }}</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input" type="text" :value="item.created_at"
                  readonly
                >
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input" type="text" :value="item.id"
                  readonly
                >
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">{{ $t('alerts.notifier') }}</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input" type="text" :value="`${item.notifier_name} (${item.notifier_id})`"
                  readonly
                >
              </p>
            </div>
          </div>
        </div>
        <hr>
        <h4 class="is-size-5 mb-2">
          {{ $t('alerts.query') }}
        </h4>
        <template v-if="item.notifier_type === NotifierThreatFrequency">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('confidence') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="item.details.query.confidence"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('severity') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="item.details.query.severity"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.notifier_type === NotifierSensorMonitor">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">CPU</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.query.cpu) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">RAM</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.query.ram) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">DISK</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.query.disk) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
        </template>
        <hr>
        <h4 class="is-size-5 mb-2">
          {{ $t('matching') }}
        </h4>
        <template v-if="item.notifier_type === NotifierThreatFrequency">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('client') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="item.details.object.src_host"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('threats.resource') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="item.details.object.resource"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('events') }} / {{ $t('threshold') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="`${item.details.events} / ${item.details.threshold}`"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.notifier_type === NotifierSensorMonitor">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">CPU</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.events.cpu) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">RAM</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.events.ram) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">DISK</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input" type="text" :value="fixed(item.details.events.disk) + '%'"
                    readonly
                  >
                </p>
              </div>
            </div>
          </div>
        </template>
        <hr>
        <div slot="footer" class="field is-grouped is-grouped-right">
          <div class="control">
            <button type="submit" class="button is-link" @click.prevent="resolve(item)">
              {{ isResolved(item) ? $t('re-open') : $t('resolve') }}
            </button>
          </div>
          <div class="control">
            <button type="button" class="button is-link is-light" @click.prevent="closeModal">
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { repo, check, issueReopened } from 'octicons-vue'
import { Modal } from '@cyradar/ui'
import { NotifierThreatFrequency, NotifierSensorMonitor } from '@/store'
import debounce from 'lodash.debounce'

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      processing: false,
      modal: false,
      item: null,
      tab: 0
    }
  },
  computed: {
    NotifierThreatFrequency () {
      return NotifierThreatFrequency
    },
    NotifierSensorMonitor () {
      return NotifierSensorMonitor
    },
    repo () {
      return repo
    },
    fixed () {
      return this.$options.filters.fixed
    },
    check () {
      return check
    },
    reopened () {
      return issueReopened
    },
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    details (item) {
      this.tab = 0
      this.item = item
      this.modal = true
    },
    isResolved (item) {
      return !!item.resolved_at
    },
    resolve (item) {
      if (this.processing) {
        return
      }

      this.processing = true
      return this.$http.patch(`/api/v1/alerts/${item.id}/resolve`, {
        resolved: !item.resolved_at
      })
        .then(body => {
          if (!body) {
            return
          }
          const text = item.resolved_at ? this.$t('re-opened') : this.$t('resolved')
          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: text
          })
          this.modal = false
        })
        .finally(() => {
          this.processing = false
        })
    },
    itemDebounceProxy: debounce(function () {
      const args = [...arguments]
      if (args.length !== 4) {
        return
      }

      this.items.apply(this, args.slice(0, 3)).then(args[3])
    }, 1000, { maxWait: 5000 }),
    itemsDebounced () {
      return new Promise(resolve => {
        this.itemDebounceProxy(...arguments, resolve)
      })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/alerts?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
