<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.alerts-overview') }}
      </h2>
      <range-picker
        ref="rangepicker"
        class="level-right"
        :range.sync="dates"
        :lookup.sync="dateRange"
        :disabled="loading"
        min-hour
      />
    </div>
    <div class="columns">
      <div class="column">
        <box>
          <p slot="header">
            {{ $t('alerts.latest') }}
          </p>
          <div v-if="statsOverview && statsOverview.latest" class="level">
            <div class="level-left">
              <h3 class="is-size-5">
                {{ latestResource }}
              </h3>
            </div>
            <div class="level-right is-hidden-touch is-hidden-desktop-only">
              <h3 class="is-size-5">
                {{ statsOverview.latest.created_at | dmyhs }}
              </h3>
            </div>
          </div>
          <p v-else>
            {{ $t('loading') }}
          </p>
        </box>
      </div>
      <div class="column">
        <box>
          <p slot="header">
            {{ $t('alerts.total') }}
          </p>
          <div v-if="statsOverview">
            <h3 class="is-size-5">
              {{ statsOverview.all }}
            </h3>
          </div>
          <p v-else>
            {{ $t('loading') }}
          </p>
        </box>
      </div>
      <div class="column">
        <box>
          <p slot="header">
            {{ $t('alerts.recent') }} <span v-if="$refs.rangepicker">- {{ $refs.rangepicker.text }}</span>
          </p>
          <div v-if="statsOverview">
            <h3 class="is-size-5">
              {{ statsOverview.current }}
            </h3>
          </div>
          <p v-else>
            {{ $t('loading') }}
          </p>
        </box>
      </div>
    </div>
    <box>
      <p slot="header">
        {{ $t('alerts.histogram-notifier') }}
      </p>
      <chart
        type="timeseries"
        :series="statsSeriesNotifierWithRange"
        :options="statsOptionsNotifierWithRange"
        :loading="loading"
        lazy
        style="height: 300px"
      />
    </box>

    <box>
      <p slot="header">
        {{ $t('alerts.bar-notifier') }}
      </p>
      <chart
        type="bar"
        :series="statsSeriesNotifier"
        :options="statsOptionsNotifier"
        :loading="loading"
        lazy
        style="height: 300px"
      />
    </box>
  </section>
</template>

<script>
import RangePicker from '@/components/RangePicker'
import Chart from '@/components/Chart'
import { statsSeriesBar, statsSeriesTimeseries } from '@/utils'
import { interpolateWarm } from 'd3'

export default {
  components: { RangePicker, Chart },
  data () {
    return {
      dateRange: 'hour-24',
      dates: [],
      loading: false,
      statsOverview: null,
      statsNotifierWithRange: null,
      statsNotifier: null,
      statsSrcWithRange: null,
      statsSeverity: null
    }
  },
  computed: {
    rangeFormat () {
      return '%d/%m/%Y %H:%M'
    },
    latestResource () {
      return this.statsOverview?.latest?.details?.object?.resource || this.statsOverview?.latest?.details?.name
    },
    sensorIDs () {
      if (!this.sensors) {
        return []
      }

      return Object.keys(this.sensors)
    },
    statsSeriesNotifierWithRange () {
      if (!this.statsNotifierWithRange || !this.statsNotifierWithRange.length) {
        return {}
      }

      return statsSeriesTimeseries(this.statsNotifierWithRange)
    },
    statsSeriesNotifier () {
      if (!this.statsNotifier) {
        return {}
      }

      return statsSeriesBar(this.statsNotifier, null, 'Alerts', {})
    },
    statsOptionsNotifierWithRange () {
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          }
        }
      }
    },
    statsOptionsNotifier () {
      return {
        data: {
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            return interpolateWarm((d.index + 1) / 10)
          }
        }
      }
    }
  },
  watch: {
    dates (v) {
      if (!v || !v.length) {
        return
      }

      this.loadStatsOverview()
      this.loadStatsNotifierWithRange()
      this.loadStatsNotifier()
    }
  },
  methods: {
    loadStatsOverview () {
      this.$http.get(`/api/v1/stats/alert/overview?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsOverview = data.data
          this.loading = false
        })
    },
    loadStatsNotifierWithRange () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/alert/notifier?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&range=auto`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsNotifierWithRange = data.data
          this.loading = false
        })
    },
    loadStatsNotifier () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/alert/notifier?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsNotifier = data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
