<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.alerts-notifiers') }}
      </h2>
      <p>
        <button
          class="button is-primary"
          @click.prevent="create"
        >
          <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
        </button>
      </p>
    </div>

    <box>
      <data-table
        ref="table"
        :items="items"
        :theme="$_ui_theme_tables"
      >
        <data-column
          field="id"
          label="ID"
        />
        <data-column
          field="name"
          :label="$t('name')"
        />
        <data-column
          field="settings.channels"
          :label="$t('channels')"
        >
          <template slot-scope="{ value }">
            <span
              v-for="(v, name) in value" v-show="name !== 'db'" :key="name"
              :title="v && v.length ? v.join(', '): ''" class="px-2 py-1 tag is-info is-light is-uppercase mr-1"
            >{{ name }}</span>
          </template>
        </data-column>
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          :sortable="false"
        >
          <template slot-scope="props">
            <button
              v-show="can(`update:notifier:${props.item.id}`)"
              class="button is-small is-warning is-light mr-2"
              @click.prevent="edit(props.item)"
            >
              <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
            </button>

            <button
              v-show="can(`update:notifier:${props.item.id}`)"
              class="button is-small is-danger is-light"
              @click.prevent="destroy(props.item)"
            >
              <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            {{ $t('newWatcher') }} <span v-if="item.name">-</span> {{ item.name }}
          </div>

          <template v-if="step === 1">
            <div class="field">
              <label class="label">{{ $t('name') }}</label>
              <div class="control">
                <input
                  v-model="item.name" class="input" type="text"
                  required placeholder="Your Alert"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('type') }}</label>
              <div class="select">
                <select v-model="item.type">
                  <option disabled>
                    {{ $t('selectDefault') }}
                  </option>
                  <option v-for="t in NotifierTypes" :key="t.value" :value="t.value">
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="item.settings && item.settings.channels" class="field">
              <label class="label">{{ $t('channels') }}</label>
              <div v-for="c in channels" :key="c">
                <label class="checkbox mr-4">
                  <input
                    v-model="itemChannelsArr" type="checkbox" :value="c"
                  >
                  <span class="ml-1 is-uppercase">{{ c }}</span>
                </label>
                <input
                  v-show="itemChannels[c] !== undefined" v-model="itemChannels[c]"
                  class="input" type="text"
                >
              </div>
            </div>
          </template>

          <template v-if="step === 2 && item.settings">
            <template v-if="item.type === NotifierThreatFrequency">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">{{ $t('severity') }}</label>
                    <div class="select is-fullwidth">
                      <select v-model="item.settings.severity">
                        <option value="any">
                          {{ $t('any') }}
                        </option>
                        <option value="low">
                          {{ $t('low') }}
                        </option>
                        <option value="medium">
                          {{ $t('medium') }}
                        </option>
                        <option value="high">
                          {{ $t('high') }}
                        </option>
                        <option value="critical">
                          {{ $t('critical') }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">{{ $t('confidence') }}</label>
                    <div class="control control-confidence">
                      <input
                        v-model="item.settings.confidence" class="input" type="number"
                        step="0.1" max="1" min="0"
                        required
                      >
                      <a class="button is-small" :disabled="item.settings.confidence === 0" @click.prevent="item.settings.confidence = 0">{{ $t('any') }}</a>
                    </div>
                    <p class="help">
                      {{ $t('alerts.notiFloat') }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t('fields') }}</label>
                <label v-for="f in trackFields" :key="f" class="checkbox mr-4">
                  <input v-model="item.settings.tracked_by" type="checkbox" :value="f">
                  <span class="ml-1 is-uppercase">{{ f }}</span>
                </label>
              </div>

              <div class="columns">
                <div class="column">
                  <label class="label">{{ $t('threshold') }}</label>
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input
                        v-model="item.settings.threshold" class="input" type="text"
                        required
                      >
                    </div>
                    <p class="control">
                      <span class="select">
                        <select v-model="item.settings.time_window">
                          <option v-for="(w, v) in timeWindows" :key="v" :value="v">{{ w }}</option>
                        </select>
                      </span>
                    </p>
                  </div>
                  <p class="help">
                    {{ $t('alerts.notiThreshold') }}
                  </p>
                </div>
                <div class="column">
                  <label class="label">{{ $t('throttle') }}</label>
                  <div class="field has-addons">
                    <p class="control">
                      <span class="select">
                        <select v-model="item.settings.throttle_wait">
                          <option v-for="(w, v) in throttleWaits" :key="v" :value="v">{{ w }}</option>
                        </select>
                      </span>
                    </p>
                    <div class="control">
                      <input
                        class="input" type="text" required
                        :value="$t('alerts.betweenAlerts')" readonly
                      >
                    </div>
                  </div>
                  <p class="help">
                    {{ $t('alerts.notiThrottle') }}
                  </p>
                </div>
              </div>
            </template>

            <template v-if="item.type === NotifierSensorMonitor">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">CPU {{ $t('threshold') }} (%)</label>
                    <div class="control">
                      <input
                        v-model="item.settings.cpu_threshold" class="input" type="number"
                        required min="10" max="100"
                      >
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">RAM {{ $t('threshold') }} (%)</label>
                    <div class="control">
                      <input
                        v-model="item.settings.ram_threshold" class="input" type="number"
                        required min="10" max="100"
                      >
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">DISK {{ $t('threshold') }} (%)</label>
                    <div class="control">
                      <input
                        v-model="item.settings.disk_threshold" class="input" type="number"
                        required min="10" max="100"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <label class="label">{{ $t('intervals') }}</label>
                  <div class="field">
                    <div class="select is-fullwidth">
                      <select v-model="item.settings.time_window">
                        <option v-for="(w, v) in timeWindows" :key="v" :value="v">
                          {{ w }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class="help">
                    The time interval for above thresolds
                  </p>
                </div>
                <div class="column">
                  <label class="label">{{ $t('throttle') }}</label>
                  <div class="field has-addons">
                    <p class="control">
                      <span class="select">
                        <select v-model="item.settings.throttle_wait">
                          <option v-for="(w, v) in throttleWaits" :key="v" :value="v">{{ w }}</option>
                        </select>
                      </span>
                    </p>
                    <div class="control">
                      <input
                        class="input" type="text" required
                        :value="$t('alerts.betweenAlerts')" readonly
                      >
                    </div>
                  </div>
                  <p class="help">
                    {{ $t('alerts.notiThrottle') }}
                  </p>
                </div>
              </div>
            </template>
          </template>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button
                v-if="step === 1" type="button" class="button is-link"
                :disabled="!nextable"
                @click.prevent="step++"
              >
                {{ $t('next') }}
              </button>

              <button v-if="step === 2" type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { pencil, plus, trashcan } from 'octicons-vue'
import { Modal } from '@cyradar/ui'
import { NotifierThreatFrequency, NotifierSensorMonitor } from '@/store'

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      modal: false,
      item: null,
      itemChannels: {},
      step: 1
    }
  },
  computed: {
    NotifierThreatFrequency () {
      return NotifierThreatFrequency
    },
    NotifierSensorMonitor () {
      return NotifierSensorMonitor
    },
    NotifierTypes () {
      return [{
        label: 'Threat Frequency',
        value: this.NotifierThreatFrequency
      }, {
        label: 'Sensor Monitor',
        value: this.NotifierSensorMonitor
      }]
    },
    plus () {
      return plus
    },
    trashcan () {
      return trashcan
    },
    pencil () {
      return pencil
    },
    channels () {
      return ['email', 'sms']
    },
    trackFields () {
      return ['src_host', 'resource', 'dst_host']
    },
    timeWindows () {
      return {
        60000000000: this.$t('inMinute'),
        1800000000000: this.$t('in30Minute'),
        3600000000000: this.$t('inHour'),
        14400000000000: this.$t('in4Hour'),
        86400000000000: this.$t('inDay')
      }
    },
    throttleWaits () {
      return {
        30000000000: this.$t('duration30Second'),
        60000000000: this.$t('durationMinute'),
        1800000000000: this.$t('duration30Minute'),
        3600000000000: this.$t('durationHour')
      }
    },
    nextable () {
      return this.item && this.item.name && this.item.type && this.item.settings
    },
    language () {
      return this.$store.state.ui.language
    },
    itemChannelsArr: {
      get () {
        if (!this.itemChannels) {
          return []
        }

        return Object.keys(this.itemChannels)
      },
      set (val) {
        if (!val) {
          return
        }

        this.itemChannels = val.reduce((acc, v) => {
          if (this.itemChannels[v]) {
            acc[v] = this.itemChannels[v]
            return acc
          }

          acc[v] = ''
          return acc
        }, {})
      }
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    },
    item (v) {
      if (!v?.settings?.channels) {
        return
      }

      this.itemChannels = Object.keys(v.settings.channels).reduce((acc, val) => {
        if (!v.settings.channels[val]) {
          return acc
        }

        acc[val] = v.settings.channels[val] instanceof Array ? v.settings.channels[val].join(',') : v.settings.channels[val]
        return acc
      }, {})
    },
    itemChannels: {
      deep: true,
      handler (v) {
        if (!v) {
          return
        }

        if (!this.item.settings) {
          return
        }

        this.item.settings.channels = Object.keys(v).reduce((acc, val) => {
          acc[val] = typeof v[val] === 'string' ? v[val].split(',') : v[val]
          return acc
        }, {})
      }
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.settings && this.item.settings.confidence) {
        this.item.settings.confidence = parseFloat(this.item.settings.confidence)
      }

      if (this.item.settings && this.item.settings.confidence) {
        this.item.settings.confidence = parseFloat(this.item.settings.confidence)
      }

      if (this.item.settings && this.item.settings.threshold) {
        this.item.settings.threshold = parseFloat(this.item.settings.threshold)
      }

      if (this.item.settings && this.item.settings.cpu_threshold) {
        this.item.settings.cpu_threshold = parseFloat(this.item.settings.cpu_threshold)
      }

      if (this.item.settings && this.item.settings.ram_threshold) {
        this.item.settings.ram_threshold = parseFloat(this.item.settings.ram_threshold)
      }

      if (this.item.settings && this.item.settings.disk_threshold) {
        this.item.settings.disk_threshold = parseFloat(this.item.settings.disk_threshold)
      }

      if (this.item.settings && this.item.settings.throttle_wait) {
        this.item.settings.throttle_wait = parseInt(this.item.settings.throttle_wait)
      }

      if (this.item.settings && this.item.settings.time_window) {
        this.item.settings.time_window = parseInt(this.item.settings.time_window)
      }

      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    resetItem () {
      this.step = 1
      this.item = {
        name: '',
        type: this.NotifierThreatFrequency,
        settings: {
          channels: {},
          severity: 'any',
          confidence: 0,
          threshold: 1,
          throttle_wait: 60000000000,
          time_window: 60000000000,
          tracked_by: [
            'src_host',
            'resource'
          ]
        }
      }
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.selectedChannels = []
      this.modal = true
    },
    store () {
      if (!this.item) {
        return
      }

      return this.$http.post('/api/v1/notifiers', this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Created'
          })

          this.modal = false
          this.resetItem()
        })
    },
    update () {
      if (!this.item) {
        return
      }

      return this.$http.patch(`/api/v1/notifiers/${this.item.id}`, this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Updated'
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/notifiers/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Deleted'
          })
        })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/notifiers?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
<style lang="scss">
.control-confidence {
  position: relative;

  .button {
    position: absolute;
    top: 0.4em;
    right: 3em;
  }
}
</style>
